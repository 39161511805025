@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700;800&display=swap');

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@font-face {
  font-family: 'ABCWhyteInktrap';
  src: url('./assets/fonts/ABCWhyteInktrap-Medium.woff2') format('woff2'),
    url('./assets/fonts/ABCWhyteInktrap-Medium.woff') format('woff'),
    url('./assets/fonts/ABCWhyteInktrap-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'ABCWhyteInktrap';
  src: url('./assets/fonts/ABCWhyteInktrap-Book.woff2') format('woff2'),
    url('./assets/fonts/ABCWhyteInktrap-Book.woff') format('woff'),
    url('./assets/fonts/ABCWhyteInktrap-Book.otf') format('opentype');
  font-weight: 300;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: theme('colors.third.95');
    color: theme('colors.third.10');
  }
  h1 {
    @apply font-header-h1;
  }
  h2 {
    @apply font-header-h2;
  }
  h3 {
    @apply font-header-h3;
  }
  h4 {
    @apply font-header-h4;
  }
  h5 {
    @apply font-header-h5;
  }
  h6 {
    @apply font-header-h6;
  }
  a {
    @apply text-primary-20 transition-all duration-300 hover:text-primary-50;
  }
  p {
    @apply font-body-3;
  }
  ol {
    @apply my-0 ml-4 list-decimal;
  }
  ul {
    @apply my-0 ml-4 list-disc;
  }
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* INPUTS IMPROVEMENTS */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* REACT SLICK SLIDER */
.slick-arrow {
  width: 31px;
  height: 31px;
}
.slick-arrow:before {
  content: '';
  display: block;
  width: 31px;
  height: 31px;
  background-color: theme('colors.primary.50');
  mask: url('./assets/icons/chevronRight.svg');
  opacity: 1;
  position: absolute;
  left: 0;
  top: 0;
  transition: left 0.25s ease-in-out;
}
.slick-prev {
  display: none !important;
}
.slick-prev:before {
  transform: rotate(180deg);
}
.slick-prev:hover:before {
  left: -5px;
}
.slick-next:hover:before {
  left: 5px;
}
.slick-slider {
  margin-bottom: 32px;
}
.slick-dots {
  height: 2px;
  bottom: -32px;
  font-size: 0;
  line-height: 0;
}
.slick-dots li,
.slick-dots li button {
  padding: 0;
  margin: 0;
  width: 8px;
  height: 2px;
}
@media not all and (max-width: 920px) {
  .slick-dots li,
  .slick-dots li button {
    width: 16px;
  }
}
@media not all and (max-width: 1200px) {
  .slick-dots li,
  .slick-dots li button {
    width: 32px;
  }
}
.slick-dots li:first-child button:before {
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
}
.slick-dots li:last-child button:before {
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
}
.slick-dots li button:before {
  content: '';
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: theme('colors.third.70');
  transition-property: background-color, opacity;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
.slick-dots li button:hover:before {
  content: '';
  opacity: 0.75;
  background-color: theme('colors.primary.40');
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  background-color: theme('colors.primary.40');
}

/* NAVBAR */
@media not all and (max-width: 639px) {
  #top-nav:checked ~ nav {
    height: 100%;
    padding-top: 3.5rem;
    padding-bottom: 0.5rem;
  }
}

/* HIDDEN SCROLLBARS */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

[data-radix-popper-content-wrapper] {
  z-index: 45 !important;
}
[data-radix-popper-content-wrapper] div[role='menuitem'] {
  cursor: default;
}

[data-radix-scroll-area-viewport] > div {
  display: block !important;
}
